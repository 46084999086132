<template>
  <div class="finance-main">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="form">
      <div class="form-main">
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span>
              财务类型：</span
            >
          </div>
          <el-select
            v-model="cateId"
            placeholder="请选择"
            size="small"
            class="app-state"
            @change="changeSelect"
          >
            <el-option
              v-for="item in financialTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 个人 -->
        <div class="box-type" v-show="cateId === 1">
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >支付宝：</span
              >
            </div>
            <el-input
              v-model="personForm.alipay"
              placeholder="请输入支付宝"
              maxlength="1000"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >姓名：</span
              >
            </div>
            <el-input
              v-model="personForm.name"
              maxlength="1000"
              placeholder="请输入姓名"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >身份证号：</span
              >
            </div>
            <el-input
              v-model="personForm.idcard"
              @blur="IdCardBlur"
              maxlength="1000"
              placeholder="请输入身份证号"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >手机号：</span
              >
            </div>
            <el-input
              v-model="personForm.phone"
              @blur="phoneNumberBlur"
              placeholder="请输入手机号"
              maxlength="1000"
            ></el-input>
          </div>
          <div class="button-box">
            <el-button @click="setFinance">保存</el-button>
          </div>
          <div class="illustrate">
            提示：个人名义付款，我们将根据国家税务要求进行申报，并且扣除相应税点。
          </div>
        </div>
        <!-- 公司 -->
        <div class="box-type" v-show="cateId === 2">
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >公司全称：</span
              >
            </div>
            <el-input
              v-model="companyForm.companyName"
              placeholder="请输入公司全称"
              maxlength="1000"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >公司地址：</span
              >
            </div>
            <el-input
              v-model="companyForm.companyAddress"
              maxlength="1000"
              placeholder="请输入公司地址"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >纳税人识别号：</span
              >
            </div>
            <el-input
              v-model="companyForm.taxpayerIdentificationNumber"
              placeholder="请输入纳税人识别号"
              maxlength="1000"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >银行账号：</span
              >
            </div>
            <el-input
              v-model="companyForm.bankAccount"
              maxlength="1000"
              placeholder="请输入银行账号"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >开户行所在地：</span
              >
            </div>
            <el-cascader
              :class="ctiyName ? 'teplaceholder' : ''"
              @change="changeLocation"
              v-model="location"
              :props="cascaderProps"
              :placeholder="ctiyName ? ctiyName : '请选择您的开户行所在地'"
            ></el-cascader>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >开户银行：</span
              >
            </div>
            <el-select
              v-model="companyForm.bankId"
              placeholder="请选择开户银行"
              size="small"
              class="app-state"
              @change="changeBankId"
            >
              <el-option
                v-for="item in bankList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="box" v-show="companyForm.bankId !== 1">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >支行名称：</span
              >
            </div>
            <el-input
              v-model="companyForm.bankBranchName"
              placeholder="请输入支行名称"
              maxlength="1000"
            ></el-input>
          </div>
          <div class="box" v-show="companyForm.bankId === 1">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >银行名称：</span
              >
            </div>
            <el-input
              v-model="companyForm.bankName"
              placeholder="请输入银行名称"
              maxlength="1000"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >联系人：</span
              >
            </div>
            <el-input
              v-model="companyForm.contactName"
              maxlength="1000"
              placeholder="请输入联系人"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >联系人手机号：</span
              >
            </div>
            <el-input
              v-model="companyForm.contactPhone"
              maxlength="1000"
              placeholder="请输入联系人手机号"
            ></el-input>
          </div>
          <div class="box">
            <div class="title">
              <img src="@/assets/images/income/star.png" alt="图片" /><span
                >联系人身份证：</span
              >
            </div>
            <el-input
              v-model="companyForm.contactIDCard"
              maxlength="1000"
              placeholder="请输入联系人身份证号"
            ></el-input>
          </div>
          <div class="button-box">
            <el-button @click="setFinance">保存</el-button>
          </div>
          <div class="illustrate">
            提示：请仔细填写财务信息，以便我们正确付款。
          </div>
        </div>
      </div>

      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import {
  getFinance,
  setFinance,
  getAllBank,
  getAllProvince,
  getAllCity,
} from "@/api/user";
export default {
  components: { Breadcrumb, Footer },
  data() {
    return {
      list: [
        {
          name: "我的收益",
          path: null,
        },
        {
          name: "财务信息",
          path: null,
        },
      ],
      financialTypes: [
        {
          value: 1,
          label: "个人",
        },
        {
          value: 2,
          label: "公司",
        },
      ],
      cateId: 2, //选中的财务类型
      personForm: {
        alipay: null,
        name: "", //姓名
        phone: "",
        idcard: "", //身份证
      },
      companyForm: {
        companyName: "", //公司名称
        companyAddress: "", //公司地址
        taxpayerIdentificationNumber: "", //纳税人识别号
        bankAccount: "", //银行账号
        bankProviceId: "",
        bankCityId: "",
        bankId: "",
        bankBranchName: "",
        bankName: "",
        contactName: "",
        contactPhone: "",
        contactIDCard: "",
      },
      location: [], //开户行所在地
      cascaderProps: {
        label: "name",
        value: "id",
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      ctiyName: null, //用来数据回显
      bankList: null,
    };
  },
  created() {
    this.getFinance();
    this.getAllBank();
  },
  methods: {
    IdCardBlur() {
      const id =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (id.test(this.personForm.idcard) === false)
        this.$message.warning("身份证输入错误");
    },
    phoneNumberBlur() {
      const phone =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/;
      if (phone.test(this.personForm.phone) === false)
        this.$message.warning("手机号码输入错误");
    },
    //切换财务类型
    changeSelect() {
      console.log("类型改变");
    },
    //切换开户银行
    changeBankId() {
      this.companyForm.bankBranchName = "";
      this.companyForm.bankName = "";
    },
    //银行所在地变化
    changeLocation() {
      this.companyForm.bankProviceId = this.location[0];
      this.companyForm.bankCityId = this.location[1];
      console.log(12, this.location);
    },
    //获取财务信息
    getFinance() {
      getFinance().then((res) => {
        console.log("获取财务信息", res.data);
        if (res.data) {
          this.cateId = res.data.cateId;
          this.personForm.alipay = res.data.alipay;
          this.personForm.name = res.data.name;
          this.personForm.phone = res.data.phone;
          this.personForm.idcard = res.data.idcard;
          this.companyForm.companyName = res.data.companyName;
          this.companyForm.companyAddress = res.data.companyAddress;
          this.companyForm.taxpayerIdentificationNumber =
            res.data.taxpayerIdentificationNumber;
          this.companyForm.bankAccount = res.data.bankAccount;
          this.companyForm.bankProviceId = res.data.bankProviceId;
          this.companyForm.bankCityId = res.data.bankCityId;
          this.companyForm.bankId = res.data.bankId;
          this.companyForm.bankBranchName = res.data.bankBranchName;
          this.companyForm.bankName = res.data.bankName;
          this.companyForm.contactName = res.data.contactName;
          this.companyForm.contactPhone = res.data.contactPhone;
          this.companyForm.contactIDCard = res.data.contactIDCard;
          if (this.companyForm.bankProviceId) {
            this.location.push(this.companyForm.bankProviceId);
            this.location.push(this.companyForm.bankCityId);
            this.getCtiy();
            console.log(1234, this.ctiyName);
          }
        }
      });
    },
    //更新财务信息
    setFinance() {
      let params;
      if (this.cateId === 1) {
        params = this.personForm;
        params.cateId = this.cateId;
      }
      if (this.cateId === 2) {
        params = this.companyForm;
        params.cateId = this.cateId;
      }

      setFinance(params).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "更新成功",
            type: "success",
          });
        }
      });
    },
    //获取全部银行
    getAllBank() {
      getAllBank().then((res) => {
        console.log("获取全部银行", res.data);
        this.bankList = res.data;
      });
    },
    lazyLoad(node, resolve) {
      this.getData(node, resolve);
    },
    async getData(node, resolve) {
      if (!node.data) {
        await getAllProvince().then((res) => {
          const nodes = res.data;
          resolve(nodes);
        });
      } else {
        console.log("node", node);
        await getAllCity({ provinceId: node.data.id }).then((res) => {
          res.data.forEach((_, index, array) => {
            array[index].leaf = 1;
          });
          const nodes = res.data;
          console.log("nodesnodes", nodes);
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes);
        });
      }
    },
    //获取更新后城市节点名称
    async getCtiy() {
      const provinceName = await getAllProvince().then((res) => {
        let name = null;
        res.data.forEach((item) => {
          if (item.id === this.companyForm.bankProviceId) {
            name = item.name;
            return;
          }
        });
        return name;
      });

      const ctiyName = await getAllCity({
        provinceId: this.companyForm.bankProviceId,
      }).then((res) => {
        let name = null;
        res.data.forEach((item) => {
          if (item.id === this.companyForm.bankCityId) {
            name = item.name;
            return;
          }
        });
        return name;
      });

      this.ctiyName = provinceName + "/" + ctiyName;
    },
    handleChange() {},
  },
};
</script>
<style lang="scss" scoped>
.finance-main {
  padding: 20px 0 0 20px;
  min-height: calc(100% - 20px);
  .form {
    min-height: calc(100vh - 153px);
    background-color: #fff;
    margin-top: 20px;
    .form-main {
      display: flex;
      flex-direction: column;
      padding: 65px 300px 0 300px;
      align-items: center;
      .box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 35px;
        .title {
          width: 120px;
          color: #06052b;
          margin-right: 10px;
          display: flex;
          justify-content: flex-end;
          img {
            widows: 12px;
            height: 12px;
          }
          span {
            font-size: 14px;
            color: #06052b;
          }
        }
        .el-select {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .el-input {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .el-cascader {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
      }
      .button-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 60px;
        .el-button {
          float: left;
          width: 100px;
          height: 36px;
          background: #4578ff;
          border-radius: 4px;
          color: #fff;
        }
      }
      .illustrate {
        width: calc(100% - 120px);
        padding-left: 140px;
        margin-top: 81px;
        font-size: 14px;
        color: #cccccc;
      }
    }
    .footer-fa {
      padding-right: 20px;
      background-color: #fff;
      margin-top: 150px;
      /deep/ .home-page-footer {
        margin-bottom: 0 !important;
        padding-bottom: 25px;
      }
    }
  }
}
.box-type {
  width: 100%;
}
.teplaceholder {
  /deep/.el-input {
    .el-input__inner::placeholder {
      color: #606266;
      opacity: 1;
    }
  }
}
</style>